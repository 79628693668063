import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const ImageWrapper = makeShortcode("ImageWrapper");
const AdaptiveImage = makeShortcode("AdaptiveImage");
const ImageCaption = makeShortcode("ImageCaption");
const Link = makeShortcode("Link");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Empty states</Heading>
      <Text mdxType="Text">
  When crafted intentionally with best practices in mind, empty states encourage
  engagement, improve learnability, and deliver essential feedback.
      </Text>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Identify your use case</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When designing an empty state, start by understanding why there's nothing to
    display.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Someone might have just installed the app and not interacted with it yet.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    A feature—new or existing—may require an action from the user side.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    There could be nothing to display and no action to take.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    The search wasn't successful, or nothing matched the filters.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    There might be no connection, the page wasn't found, or the access is
    limited.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">App or feature first use</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Heading tip: think of what there will be instead of what's missing.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use the description to educate, motivate, and guide people to the next
    steps.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When someone starts using an app, it's an organic opportunity to foster
    interaction.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Unlike guided tours, empty states appear in context and don't interrupt user
    flow.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    You can use them to reduce time to value and improve the system's
    learnability.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't repeat your heading in the description. That's wasted space.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't try to cover everything. Aim to limit your description to 2 lines.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/882c4e6f-7ba5-41b0-bcd0-77cefe5432c8" darkSrc="https://github.com/user-attachments/assets/600f8bfc-963a-4efd-b0a0-097fc511e95d" alt="An example of an empty state for the first time someone uses a feature or app." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  Saying <i>Add your first [what]</i> is an easy yet effective way to encourage
  action. The description outlines the benefits, and the call to action clearly
  matches the heading.
      </ImageCaption>
      <br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/13238c07-dee7-4991-9207-106737e62f7b" darkSrc="https://github.com/user-attachments/assets/5fef2d19-2627-4a2e-9291-31d70fc20fc4" alt="An example of an empty state for the first time someone uses a feature or app." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  To help people grasp the value right away, you might want to bring it to the
  heading. This clarifies the purpose and enhances engagement.
      </ImageCaption>
      <br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/9d0af876-ce99-497b-a486-03e0fc8fd3b8" darkSrc="https://github.com/user-attachments/assets/15a1e771-ed83-4a19-9fcc-8a0d31728e64" alt="An example of an empty state for the first time someone uses a feature or app." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  This heading clearly outlines the action to take, allowing the description to
  address the system's temporary constraints and improve the feature's
  learnability.
      </ImageCaption>
      <br></br>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">
    There's nothing here, but there will be
  </DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Sometimes there's nothing to display and viewers can't take any action.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Keep headings informative and emphasize that the state they describe is
    temporary.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use the description to educate people further or highlight the benefit.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't add external links just to fill space. Include them only if they bring
    value.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/0071abd1-0a0d-446d-8a6c-ded82660565c" darkSrc="https://github.com/user-attachments/assets/f0f58ca6-f9d5-4a77-a1e8-52e0ff33cdd5" alt="An example of an empty state that indicates the user needs to wait for an action to complete before content appears." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  Adding <i>yet</i> at the end of the heading indicates a temporary state. The
  description explains what needs to happen first, and the link offers relevant
  resources.
      </ImageCaption>
      <br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/e466c583-7b33-44ec-b112-6cedb1a1b918" darkSrc="https://github.com/user-attachments/assets/d5f133cf-239e-46a4-8df6-0274c7d0978c" alt="An example of an empty state that indicates the user needs to wait for an action to complete before content appears." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  This heading focuses on the feature's value, while the description educates
  first-time users about what to expect and how to leverage it effectively.
      </ImageCaption>
      <br></br>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">No results found</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When the search or filters return no results, provide immediate, clear
    feedback.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Start by clearly and concisely communicating the outcome.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Then, offer a helpful tip, such as trying a different keyword or adjusting
    the filters.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Sounds too general? Consider other options, like pointing to advanced
    search.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Those empty states are highly contextual and often appear inline. Don't
    overlook them.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/0a532369-d4df-48cb-8cac-635bf3b45e54" darkSrc="https://github.com/user-attachments/assets/364392cb-b243-44b5-9747-e83566b9f380" alt="An example of an empty state is when no tasks match the filters." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  This type of empty state is responsible for providing feedback to the user.
  Ensure they understand what happened and provide helpful tips.
      </ImageCaption>
      <br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/0752e774-c038-41b1-a8c9-81c93a4a4161" darkSrc="https://github.com/user-attachments/assets/a6d68922-e581-4428-9bdd-677a9ae53e41" alt="An example of an empty state when the search returns no results." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  An empty state doesn't always have to take up the entire screen. Contextual
  help makes it easier to apply feedback and remember how the system works.
      </ImageCaption>
      <br></br>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">
    Errors that appear as empty states
  </DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    It might happen that things don't go as expected, and people can't access
    the app.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    These issues often relate to a lack of connection, permissions, or removed
    content.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Design those empty states to offer guidance and provide a way out.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    For the heading, summarize what happened—and never blame the user.<br></br>
    “Insufficient permissions” or “We couldn't find this page” will work.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When the user needs to take action, aim to start with it. For example:<br></br>
    “Enable your cookies,” “Upgrade to the new version,” or “Check your
    connection.”
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use the description to explain any necessary details or next steps.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    You might want to add some warmth if the problem is on the system's side.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/5568d101-91e9-4788-ba88-f117a9a45fd9" darkSrc="https://github.com/user-attachments/assets/9ff5512e-9412-41c1-ba9c-e898f93ce643" alt="An example of an empty state when the user lacks permission." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  The heading doesn't refer to the user directly, making it friendlier.
  Including a helpful feature like a permission helper enhances the overall
  usability.
      </ImageCaption>
      <br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/a1919fe4-4c5e-48ce-8712-ed33f330e5de" darkSrc="https://github.com/user-attachments/assets/9a6cd3c3-48bc-4727-96ce-7fe079d8a83e" alt="An example of an empty state when the user has cookies disabled." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  When people quickly scan this empty state, they immediately know what to do.
  <br></br>
  They can also easily find additional information if needed.
      </ImageCaption>
      <br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/5762bc74-1bb7-41e1-8b3d-22cf68b24b03" darkSrc="https://github.com/user-attachments/assets/5528ffad-eb7d-42af-8d31-0215c83e9d24" alt="An example of an empty state when the connection couldn't be established." mdxType="AdaptiveImage" />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  This empty state takes responsibility for the system issue, which helps build
  trust.<br></br>A few extra words of explanation reassure people that it's not their
  fault.
      </ImageCaption>
      <br></br>
      <DoDontChecklist mdxType="DoDontChecklist">
  Discover more tips on creating{" "}
  <Link href={`https://www.fuegokit.design/content/error-messages`} mdxType="Link">
    user-friendly error messages.
  </Link>{" "}
      </DoDontChecklist>
      <hr />
      <Text mdxType="Text">Share your feedback on the #design-systems channel in Slack.</Text>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      